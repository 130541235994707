import {Pages}                  from "./Pages";
import {StateHandler}           from "../../modules/StateHandler";
import {GROUP_STATE}            from "../Enums";
import ReactDOM                 from "react-dom";
import React                    from "react";
import {EntitiesSearchDropList} from "../components/EntitiesSearchDropList";
import {Entities}               from "../enums/Entities";

export class Groups extends Pages{

    initPageHandlers() {

        super.initPageHandlers();

        let $document = $(document),
            stateHandler;

        this.openFilterList = this.openFilterList.bind(this);
        this.data = window.diaryJsData;

        if (this.data.templateIsInit) {

            stateHandler = new StateHandler({
                "states": {
                    open: GROUP_STATE.open,
                    close: GROUP_STATE.close,
                },
                "statesLabels": this.data.groupStatesLabel,
            });

            ReactDOM.render(
                <EntitiesSearchDropList
                    placeholder={"быстрый поиск по названию или ID группы"}
                    entity={Entities.GROUPS}/>,
                document.getElementById('groups-search-form'),
            );

            $document.on("click", "[data-select-name]", this.openFilterList);

            $document.on('click touchstart', () => {
                $("[data-select-source]").hide();
            });

        }

    }

    openFilterList(event) {
        let $clicked = $(event.target),
            type = $clicked.attr("data-select-name"),
            $list = $("#" + type + "_filter_list");
        event.stopPropagation();
        $("[data-select-source]").hide();
        $list
            .appendTo($("body"))
            .css({"top": event.pageY + 8, "left": event.pageX})
            .toggle();
    }
}
